import { useCallback } from "react";
import { BroadcastMessage } from "../../ApiProvider/PubSubClient";
import { BroadcastGameIdSchema } from "./getLiveBroadcasts";
import { usePubSubClientEvents } from "../../../shared/hooks/usePubSubClientEvents";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import { useGetLiveBroadcasts } from "./getLiveBroadcasts/useGetLiveBroadcasts";

interface UseFetchLiveBroadcastsProps {
  onLiveBroadcastStart?: () => void;
  enabled?: boolean;
}

/**
 * Fetches live broadcasts for the current game context and subscribes to PubSub events in order to refetch the data when a broadcast goes live or offline.
 * Live broadcasts are only supported for `cs` and `dota`.
 */
export const useFetchLiveBroadcasts = (options?: UseFetchLiveBroadcastsProps) => {
  const { gameId } = useGameId();
  const {
    data: broadcasts,
    refetch,
    isLoading,
  } = useGetLiveBroadcasts({
    enabled: options?.enabled === undefined ? true : options.enabled,
  });

  const parsedGameId = BroadcastGameIdSchema.safeParse(gameId);
  const filteredBroadcasts = !gameId
    ? broadcasts // For the main home page where no game is selected
    : !parsedGameId.success
      ? []
      : broadcasts?.filter((broadcast) => {
          return broadcast?.gameIds.includes(parsedGameId.data);
        });

  const { onLiveBroadcastStart } = options || {};
  // listener for the PubSub events that indicate changes in live broadcasts
  const broadcastsListener = useCallback(
    (broadcastMessage: BroadcastMessage) => {
      switch (broadcastMessage.action) {
        case "went-live":
          refetch();
          onLiveBroadcastStart?.();
          break;
        case "went-offline":
          refetch();
          break;
        default:
          break;
      }
    },
    [onLiveBroadcastStart, refetch],
  );
  usePubSubClientEvents({
    broadcastsListener,
    disabled: isLoading,
  });

  return {
    data: filteredBroadcasts,
    isLoading,
  };
};
