import { GameId } from "./types";

export const gameTitles: Record<"cs" | "dota" | "rl" | "gaming" | "deadlock", string> = {
  cs: "CS2",
  dota: "Dota 2",
  rl: "Rocket League",
  gaming: "News",
  deadlock: "Deadlock",
};

export const allGameIds: GameId[] = ["cs", "dota", "gaming"];
