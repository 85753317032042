import classNames from "classnames";
interface SpinnerProps {
  className?: string;
}

export function Loader({ className }: SpinnerProps) {
  return (
    <div className={classNames("relative my-25 h-[3px] w-50 overflow-hidden rounded-full bg-neutral", className)}>
      <div className="absolute top-0 -left-1/2 h-full w-2/5 animate-loader-line rounded-full bg-background-80" />
    </div>
  );
}
