import { z } from "zod";

export const TournamentGamesSchema = z.enum(["cs", "dota", "clashroyale", "clashofclans", "brawlstars", "rl"]);
export type TournamentGames = z.infer<typeof TournamentGamesSchema>;

export const FavouriteTeamsGameIdsSchema = z.enum(["cs", "dota"]);
export type FavouriteTeamsGameIds = z.infer<typeof FavouriteTeamsGameIdsSchema>;

export const GameIdSchema = z.enum(["cs", "dota", "rl", "gaming"]);
export type GameId = z.infer<typeof GameIdSchema>;
