import { useEffect } from "react";
import { Navigate, Route, Routes, generatePath, useLocation, useParams } from "react-router";
import { useFirstLoginCheck } from "./shared/hooks/useFirstLoginCheck";
import { useSignupCheck } from "./shared/hooks/useSignupCheck";
import { lazyWithRefresh } from "./lazyWithRefresh";
import { ProtectedRoute } from "./shared/components/ProtectedRoute";
import { usePostPage } from "./shared/hooks/usePostPage";
import { useAuth } from "./auth/AuthProvider/hooks/useAuth";
import { useUserWallet } from "./shared/hooks/useUserWallet";
import { useSignUpLoginErrorCheck } from "@shared/hooks/signupLoginErrorCheck";

const ProfilePage = lazyWithRefresh(() => import("./routes/ProfilePage"));
const LeaderboardLayout = lazyWithRefresh(
  () => import("./routes/FantasyPage/routes/FantasyLeaderboardPage/components/LeaderboardLayout"),
);
const FantasyJoinPage = lazyWithRefresh(() => import("./routes/FantasyPage/routes/FantasyJoinPage"));
const ProfileView = lazyWithRefresh(() => import("./routes/ProfilePage/components/ProfileView"));
const ProfileRedeem = lazyWithRefresh(() => import("./routes/ProfilePage/components/ProfileRedeem"));
const ProfileRewards = lazyWithRefresh(() => import("./routes/ProfilePage/components/ProfileRewards"));
const CSGuessrPage = lazyWithRefresh(() => import("./routes/CSGuessr"));
const CSGuessrLocalPage = lazyWithRefresh(() => import("./routes/CSGuessrLocal"));
const ClipEmbedPage = lazyWithRefresh(() => import("./routes/Clip/embed"));
const RainbowSixPage = lazyWithRefresh(() => import("./routes/RainbowSixPage"));
const FantasyLeaderboardPage = lazyWithRefresh(() => import("./routes/FantasyPage/routes/FantasyLeaderboardPage"));
const FantasyUserPage = lazyWithRefresh(() => import("./routes/FantasyPage/routes/FantasyUserPage"));
const CounterStriklePage = lazyWithRefresh(() => import("./routes/CounterStriklePage"));
const LivePage = lazyWithRefresh(() => import("./routes/Live"));
const BroadcastEnded = lazyWithRefresh(() => import("./routes/BroadcastEnded"));
const ContentPage = lazyWithRefresh(() => import("./routes/ContentPage"));
const HomePage = lazyWithRefresh(() => import("./routes/HomePage"));
const PickemsPage = lazyWithRefresh(() => import("./routes/Pickems"));
const TeamPage = lazyWithRefresh(() => import("./routes/TeamPage").then((module) => ({ default: module.TeamPage })));
const MatchPage = lazyWithRefresh(() => import("./routes/MatchPage"));
const RewatchPage = lazyWithRefresh(() => import("./routes/Rewatch"));
const ClipPage = lazyWithRefresh(() => import("./routes/Clip"));
const ValvePickemsPage = lazyWithRefresh(() => import("./routes/ValvePickems"));
const FantasyPage = lazyWithRefresh(() => import("./routes/FantasyPage"));
const SignUpPage = lazyWithRefresh(() => import("./routes/SignUpPage"));
const ForgotPasswordPage = lazyWithRefresh(() => import("./routes/ForgotPasswordPage"));
const VerifyEmailErrorPage = lazyWithRefresh(() => import("./routes/VerifyEmailErrorPage"));
const ModuleFederationTestPage = lazyWithRefresh(() => import("./routes/ModuleFederationTestPage"));
const PlayerProfilePage = lazyWithRefresh(() => import("./routes/Player/PlayerPage"));
const PlayerRedirect = lazyWithRefresh(() => import("./routes/Player/PlayerPageRedirect"));
const HigherOrLower = lazyWithRefresh(() => import("./routes/HigherLower/index"));
const HigherOrLowerClassic = lazyWithRefresh(() => import("./routes/HigherLower/ClassicHigherLower"));
const HigherOrLowerDaily = lazyWithRefresh(() => import("./routes/HigherLower/DailyHigherLower"));
const CSValveRanking = lazyWithRefresh(() => import("./routes/CSValveRankingsPage"));
const TournamentPage = lazyWithRefresh(() => import("./routes/Tournaments"));
const CookieDeclaration = lazyWithRefresh(() => import("./routes/CookieDeclaration"));
const DotaUsersSearchPage = lazyWithRefresh(() => import("./routes/DotaUsersPage"));
const DotlePage = lazyWithRefresh(() => import("./routes/DotlePage"));
const DotaUserDetailsPage = lazyWithRefresh(() => import("./routes/DotaUsersPage/routes/DotaUserDetailsPage"));
const DotaFantasyPage = lazyWithRefresh(() => import("./routes/Dota/Fantasy/routes/FantasyPage"));
const DotaFantasyJoinLeague = lazyWithRefresh(() => import("./routes/Dota/Fantasy/routes/JoinLeague"));
const DotaFantasyLeaderboard = lazyWithRefresh(() => import("./routes/Dota/Fantasy/routes/Leaderboard"));
const DotaFantasyUserPage = lazyWithRefresh(() => import("./routes/Dota/Fantasy/routes/UserPage"));
const DotaProStatsPage = lazyWithRefresh(() => import("./routes/Dota/DotaProStatsPage"));
const DotaProStatsPlayerPage = lazyWithRefresh(
  () => import("./routes/Dota/DotaProStatsPage/components/PlayerStatsPage"),
);
const DotaMatchPage = lazyWithRefresh(() => import("./routes/Dota/DotaMatchPage"));
const DotaLive = lazyWithRefresh(() => import("./routes/DotaLive"));
const Redirect = ({ to }: { to: string }) => {
  const params = useParams();
  const path = generatePath(to, params);
  return <Navigate to={path} replace />;
};

export function Routing(): JSX.Element {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const { mutate: postPointsPageEvent } = usePostPage();
  const { data: wallet } = useUserWallet();

  const walletExists = !!wallet;

  useEffect(() => {
    // Venatus Ads (Page Session Tracking)
    window.window.__VM = window.__VM || [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.__VM.push((_: any, scope: any) => {
      scope.Instances.pageManager.on(
        "navigated",
        () => {
          scope.Instances.pageManager.newPageSession(false);
        },
        false,
      );
    });
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && walletExists) {
      postPointsPageEvent();
    }
  }, [isAuthenticated, postPointsPageEvent, walletExists]);

  useSignupCheck();
  useFirstLoginCheck();
  useSignUpLoginErrorCheck();

  return (
    <Routes>
      {/* ContentPage can load the home page data from the CMS  */}
      <Route path="/" element={<HomePage />} />
      <Route path="/live">
        <Route index element={<LivePage />} />
        <Route path="end" element={<BroadcastEnded />} />
        <Route path=":broadcastSlug" element={<LivePage />} />
      </Route>

      <Route path="/pickems" element={<PickemsPage />} />
      <Route path="/teams/:slug" element={<TeamPage />} />
      <Route path="/rewatch/:id" element={<RewatchPage />} />
      <Route path="/clips/embed/:id/:slug?" element={<ClipEmbedPage />} />
      <Route path="/clips/:id/:slug?" element={<ClipPage />} />
      <Route path="/major/pickems" element={<ValvePickemsPage />} />
      <Route path="/cs/valve-rankings" element={<CSValveRanking />} />

      <Route path="/fantasy">
        <Route index element={<FantasyPage />} />
        <Route path="join/:joinCode" element={<FantasyJoinPage />} />
        <Route path="leaderboard" element={<LeaderboardLayout />}>
          <Route index element={<FantasyLeaderboardPage />} />
          <Route path=":seasonId" element={<FantasyLeaderboardPage />} />
          <Route path=":seasonId/:eventId" element={<FantasyLeaderboardPage />} />
        </Route>
        <Route path="league/:leagueId" element={<LeaderboardLayout />}>
          <Route index element={<FantasyLeaderboardPage />} />
          <Route path=":seasonId" element={<FantasyLeaderboardPage />} />
          <Route path=":seasonId/:eventId" element={<FantasyLeaderboardPage />} />
        </Route>
        <Route path=":seasonId/:eventId/:userIdentifier" element={<FantasyUserPage />} />
        {/* Redirect Legacy Routes */}
        <Route path="community/:seasonId" element={<Redirect to="/fantasy/leaderboard/:seasonId" />}></Route>
        <Route
          path="community/:seasonId/:eventId"
          element={<Redirect to="/fantasy/leaderboard/:seasonId/:eventId" />}
        ></Route>
        <Route
          path="private/:seasonId/:eventId/:leagueId"
          element={<Redirect to="/fantasy/league/:leagueId/:seasonId/:eventId" />}
        ></Route>
      </Route>
      <Route path="/higher-lower" element={<HigherOrLower />} />
      <Route path="/higher-lower/classic" element={<HigherOrLowerClassic />} />
      <Route path="/higher-lower/daily" element={<HigherOrLowerDaily />} />

      <Route path="/player/:playerId/:nickname" element={<PlayerProfilePage />} />
      <Route path="/player/:playerId" element={<PlayerRedirect />} />

      <Route path="/:circuitSlug/:tournamentId/match/:shortSeriesId" element={<MatchPage />} />
      <Route path="/:circuitSlug/:tournamentId/match/:shortSeriesId/:teams" element={<MatchPage />} />

      <Route path="/r6/gottem" element={<RainbowSixPage />} />

      <Route path="/cs">
        <Route path="" element={<HomePage />} />
        <Route path="tournaments/:tournamentId">
          <Route path="" element={<TournamentPage />} />
          <Route path="match/:shortSeriesId" element={<MatchPage />} />
          <Route path="match/:shortSeriesId/:teams" element={<MatchPage />} />
        </Route>
      </Route>
      <Route path="/dota">
        <Route index element={<HomePage />} />
        <Route path="live">
          <Route
            index
            element={
              <ProtectedRoute requiredFlag="frontend_dota_live" requiredAuth={false}>
                <DotaLive />
              </ProtectedRoute>
            }
          />
          <Route
            path=":broadcastSlug"
            element={
              <ProtectedRoute requiredFlag="frontend_dota_live" requiredAuth={false}>
                <DotaLive />
              </ProtectedRoute>
            }
          />
          <Route path="end" element={<BroadcastEnded />} />
        </Route>
        <Route path="tournaments/:tournamentId" element={<TournamentPage />} />
        <Route path="tournaments/:tournamentSlug/match/:matchId" element={<DotaMatchPage />} />
        <Route path="tournaments/:tournamentSlug/match/:matchId/:teams" element={<DotaMatchPage />} />
        <Route path="fantasy">
          <Route index element={<DotaFantasyPage />} />
          <Route path="join/:joinCode" element={<DotaFantasyJoinLeague />} />
          <Route path="leaderboard">
            <Route index element={<DotaFantasyLeaderboard />} />
            <Route path=":seasonId" element={<DotaFantasyLeaderboard />} />
            <Route path=":seasonId/:eventId" element={<DotaFantasyLeaderboard />} />
          </Route>
          <Route path="league/:leagueId">
            <Route index element={<DotaFantasyLeaderboard />} />
            <Route path=":seasonId" element={<DotaFantasyLeaderboard />} />
            <Route path=":seasonId/:eventId" element={<DotaFantasyLeaderboard />} />
          </Route>
          <Route path=":seasonId/:eventId/:userIdentifier" element={<DotaFantasyUserPage />} />
        </Route>
        <Route path="users">
          <Route index element={<DotaUsersSearchPage />} />
          <Route path=":accountId" element={<DotaUserDetailsPage />} />
        </Route>
        <Route path="stats">
          <Route index element={<DotaProStatsPage />} />
          <Route path="players" element={<DotaProStatsPlayerPage />} />
        </Route>
        <Route path="dotle" element={<DotlePage />} />
      </Route>
      <Route path="/rl">
        <Route index element={<HomePage />} />
      </Route>
      {/* User management */}
      <Route
        path="/profile/*"
        element={
          <ProtectedRoute requiredAuth>
            <ProfilePage />
          </ProtectedRoute>
        }
      >
        <Route path="" element={<ProfileView />} />
        <Route path="redeem" element={<ProfileRedeem />} />
        <Route path="rewards" element={<ProfileRewards />} />
      </Route>
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />

      <Route path="/confirmation-error" element={<VerifyEmailErrorPage />} />

      <Route path="/counter-strikle/daily" element={<CounterStriklePage />} />
      <Route path="/counter-strikle" element={<Redirect to="/counter-strikle/daily" />} />

      <Route path="/cs-guessr" element={<CSGuessrPage />} />
      <Route
        path="/cs-guessr-blast"
        element={
          <ProtectedRoute requiredFlag="cs_guessr_blast" requiredAuth>
            <CSGuessrLocalPage />
          </ProtectedRoute>
        }
      />
      {/* Allows us to test that the remote module is loading OK */}
      <Route path="/module-federation-test" element={<ModuleFederationTestPage />} />
      <Route path="/cookie-declaration" element={<CookieDeclaration />} />
      {/* Routes can be defined in the CMS, handled by ContentPage  */}
      <Route path="*" element={<ContentPage />} />
    </Routes>
  );
}
