import { Link } from "react-router";
import { ReactNode } from "react";
import { isSsrPage } from "../../../ssrPagesRoutes/isSsrPage";
import { environment, ssrSiteUrl } from "../../config";

export const AdaptiveLink = ({
  to,
  children,
  className,
  onClick,
}: {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const isSsr = isSsrPage({ pathname: to, environment: environment });

  if (isSsr) {
    return (
      <a href={`${ssrSiteUrl}${to}`} className={className} onClick={onClick}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};
