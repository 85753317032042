import { createContext } from "react";
import { allGameIds } from "./constants";
import { GameId } from "./types";

type GameIdContextValue = {
  gameId: GameId | null; // current game id, null means global context
  enabledGames: GameId[]; // game ids that are enabled via feature flags
  allGameIds: GameId[]; // all supported game ids
};

export const GameIdContext = createContext<GameIdContextValue>({
  gameId: null,
  allGameIds,
  enabledGames: ["cs", "dota", "gaming"],
});
