import { ReactNode } from "react";
import { useGameIdFromLocation } from "./useGameIdFromLocation";
import { GameIdContext } from "./context";
import { GameId } from "./types";
import { allGameIds } from "./constants";

export const GameIdProvider = ({ children }: { children: ReactNode }) => {
  const enabledGames: GameId[] = ["cs", "dota", "rl", "gaming"];

  const gameIdFromLocation = useGameIdFromLocation({ enabledGames });
  const gameId = enabledGames.length === 1 ? enabledGames[0] : gameIdFromLocation;

  return <GameIdContext.Provider value={{ allGameIds, gameId, enabledGames }}>{children}</GameIdContext.Provider>;
};
