/* eslint-disable react-refresh/only-export-components */
import { Transition } from "@headlessui/react";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { AlertNotification } from "./components/AlertNotification";
import { PointsNotification } from "./components/PointsNotification";
import { DateTime } from "luxon";

interface PointsNotification {
  id: number;
  context: "points";
  amount: number;
  title: string;
  message: string;
}

interface Notification {
  context: "alert";
  id: number;
  type: "success" | "error" | "info";
  message: string;
}

type SharedNotification = PointsNotification | Notification;

interface AlertProviderValue {
  showSuccessAlert: (message: string) => void;
  showFailureAlert: (message: string) => void;
  showInfoAlert: (message: string) => void;
  addPointsNotification: (notification: { id: number; amount: number; title: string; message: string }) => void;
}

export const AlertContext = createContext<AlertProviderValue | undefined>(undefined);

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<SharedNotification[]>([]);

  const removeNotification = useCallback((id: number) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  const addNotification = useCallback(
    (type: "success" | "error" | "info", message: string) => {
      const id = DateTime.now().toMillis();
      setNotifications((prev) => [...prev, { id, type, message, context: "alert" }]);
      setTimeout(() => removeNotification(id), 5000);
    },
    [removeNotification],
  );
  const showSuccessAlert = useCallback(
    (message: string) => {
      addNotification("success", message);
    },
    [addNotification],
  );

  const showFailureAlert = useCallback(
    (message: string) => {
      addNotification("error", message);
    },
    [addNotification],
  );

  const showInfoAlert = useCallback(
    (message: string) => {
      addNotification("info", message);
    },
    [addNotification],
  );

  const addPointsNotification: AlertProviderValue["addPointsNotification"] = useCallback(
    (notification: Parameters<AlertProviderValue["addPointsNotification"]>[0]) => {
      setNotifications((prev) => {
        const existingNotification = prev.find((n) => n.id === notification.id);
        if (existingNotification) {
          // If the notification already exists, we skip it
          return prev;
        }

        return [...prev, { ...notification, type: "success", context: "points" }];
      });
      setTimeout(() => removeNotification(notification.id), 5000);
    },
    [removeNotification],
  );

  const memoizedValue = useMemo(
    () => ({
      showSuccessAlert,
      showFailureAlert,
      showInfoAlert,
      addPointsNotification,
    }),
    [showSuccessAlert, showFailureAlert, showInfoAlert, addPointsNotification],
  );

  return (
    <AlertContext.Provider value={memoizedValue}>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-toast flex items-end p-2 sm:items-start sm:px-6 sm:py-16"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification) => (
            <Transition
              key={notification.id}
              enter="transition-all duration-300 ease-out"
              enterFrom="translate-y-2 opacity-0 sm:translate-x-2 sm:translate-y-0"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show={true}
              className="w-full max-w-72"
            >
              {notification.context === "alert" && (
                <AlertNotification type={notification.type} message={notification.message} />
              )}

              {notification.context === "points" && (
                <PointsNotification
                  amount={notification.amount}
                  title={notification.title}
                  message={notification.message}
                />
              )}
            </Transition>
          ))}
        </div>
      </div>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider };
