/* eslint-disable react-refresh/only-export-components */
import { ReactNode, createContext, useState, useMemo, useCallback } from "react";
import { useUpdateEffect } from "@shared/hooks/useUpdateEffect";
import { useUserProfile } from "../../auth/useUserProfile";
import { useGetAvatars } from "../../api/data/assets/getAvatars/useGetAvatars";
import { useUpdateProfile } from "../../routes/ProfilePage/hooks/useUpdateProfile";
import { AvatarModalContent } from "./components/AvatarModalContent";

interface AvatarModalProviderProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  setNewAvatarId: (avatarId: string) => void;
}

export const ChangeAvatarModalContext = createContext<AvatarModalProviderProps | undefined>(undefined);

export const AvatarModalProvider = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: avatars, isLoading } = useGetAvatars();
  const user = useUserProfile();

  const {
    mutate: updateAvatar,
    isPending: isUpdating,
    isSuccess,
  } = useUpdateProfile({
    userId: user?.id,
  });

  useUpdateEffect(() => {
    if (isSuccess) {
      setIsOpen(false);
    }
  }, [isSuccess]);

  const [newAvatarId, setNewAvatarId] = useState("");

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => {
      setNewAvatarId("");
    }, 250);
  }, []);

  const providerValue = useMemo(() => {
    return {
      setIsOpen,
      isOpen,
      setNewAvatarId,
    };
  }, [isOpen]);

  return (
    <ChangeAvatarModalContext.Provider value={providerValue}>
      <AvatarModalContent
        {...{ handleClose, isOpen, newAvatarId, setNewAvatarId, user, isLoading, avatars, isUpdating, updateAvatar }}
        onConfirm={() => {
          updateAvatar({ avatarId: newAvatarId });
        }}
        userAvatarId={user?.avatarId}
        userUnlockedAvatarIds={user?.avatars}
      />
      {children}
    </ChangeAvatarModalContext.Provider>
  );
};
