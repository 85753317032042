import { useEffect, useMemo, useState } from "react";
import { useGameId } from "@providers/GameIdProvider/useGameId";
import classNames from "classnames";
import { Link, useNavigate } from "react-router";
import { GameIcon } from "@shared/components/GameIcon";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react-v2";
import { Icon } from "@shared/components/Icon";
import { isSsrPage } from "../../../../../ssrPagesRoutes/isSsrPage";
import { deadlockBaseURL, environment, ssrSiteUrl } from "../../../../config";
import { gameTitles } from "@providers/GameIdProvider/constants";

export const GameNav = ({ className }: { className?: string }) => {
  const { gameId, enabledGames: enabledGameIds } = useGameId();
  const [selectedGameId, setSelectedGameId] = useState<typeof gameId | "deadlock">(gameId);
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedGameId(gameId);
  }, [gameId]);

  const games: ((typeof enabledGameIds)[number] | "deadlock")[] = useMemo(() => {
    const games: ((typeof enabledGameIds)[number] | "deadlock")[] = [
      ...enabledGameIds,
      "deadlock", // Deadlock is not an enabled game in this app, we just want to have a link to it
    ];

    // Order
    // 1. cs
    // 2. dota
    // 3. rl
    // 4. deadlock
    // ... other games

    const orderedGames = games.sort((a, b) => {
      if (a === "cs") return -1;
      if (b === "cs") return 1;
      if (a === "dota") return -1;
      if (b === "dota") return 1;
      if (a === "rl") return -1;
      if (b === "rl") return 1;
      if (a === "deadlock") return -1;
      if (b === "deadlock") return 1;

      return 0;
    });

    return orderedGames;
  }, [enabledGameIds]);

  if (games.length <= 1) {
    return null;
  }

  if (!selectedGameId) {
    return (
      <div className={classNames("flex h-full gap-4 py-2 max-sd:flex-col sd:shrink-0 sd:items-center", className)}>
        {games.map((gameId) => (
          <GameLink key={gameId} gameId={gameId} />
        ))}
      </div>
    );
  }

  return (
    <Listbox
      value={selectedGameId}
      onChange={(value) => {
        setSelectedGameId(value);

        if (value === "deadlock") {
          // https://deadlock.blast.tv or https://deadlock.blast-dev.tv
          window.location.href = deadlockBaseURL;
          return;
        }

        const isSsr = isSsrPage({ pathname: `/${value}`, environment: environment });
        if (isSsr) {
          window.location.href = `${ssrSiteUrl}/${value}`;
        } else {
          navigate(value);
        }
      }}
    >
      <ListboxButton
        className={classNames(
          "relative flex items-center gap-2 rounded bg-background-95 px-5 py-3 max-md:w-full max-md:justify-start md:min-w-48",
          className,
        )}
      >
        <GameIcon gameId={selectedGameId} className="block size-7 shrink-0 sd:size-4" />
        <span className="font-style-label-l1 sd:font-style-label-l4">{gameTitles[selectedGameId]}</span>
        <Icon
          icon="arrowDownNavbar"
          className="group pointer-events-none absolute top-1/2 right-4 size-2 -translate-y-1/2 text-foreground-90"
        />
      </ListboxButton>
      <ListboxOptions
        anchor="bottom"
        transition
        className={classNames(
          "z-navbar w-[var(--button-width)] rounded bg-background-95 [--anchor-gap:0.25rem] focus:outline-hidden",
          "origin-top transition duration-150 ease-out data-closed:-translate-y-4 data-closed:opacity-0 data-leave:data-closed:opacity-0",
          "shadow-xl shadow-background-100/50",
        )}
      >
        {games.map((gameId) => (
          <ListboxOption
            key={gameId}
            value={gameId}
            className={classNames(
              "flex cursor-pointer items-center gap-2 border border-solid border-transparent bg-background-95 px-5 py-3 data-focus:bg-background-90 data-selected:hidden",
              "transition-all",
            )}
          >
            <GameIcon gameId={gameId} className="block size-5 shrink-0 sd:size-4" />
            <span className="font-style-label-l3 sd:font-style-label-l4">{gameTitles[gameId]}</span>
          </ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};

const GameLink = ({ gameId }: { gameId: "cs" | "dota" | "rl" | "gaming" | "deadlock" }) => {
  const externalHref = useMemo(() => {
    if (gameId === "deadlock") {
      // Open deadlock.blast.tv or deadlock.blast-dev.tv
      return deadlockBaseURL;
    }

    const isSsr = isSsrPage({ pathname: `/${gameId}`, environment: environment });

    if (isSsr) {
      // Open in SPA site
      return `${ssrSiteUrl}/${gameId}`;
    }

    return null; // Open within current app
  }, [gameId]);

  const children = (
    <>
      <GameIcon gameId={gameId} className="block size-7 shrink-0 sd:size-4" />
      <span className="block shrink-0 whitespace-nowrap max-sd:font-style-label-l1 sd:font-style-label-l4">
        {gameTitles[gameId]}
      </span>
    </>
  );

  if (externalHref) {
    return (
      <a
        key={gameId}
        href={externalHref}
        className="flex shrink-0 items-center gap-2 rounded border border-solid border-transparent transition-all duration-150 ease-in-out max-sd:py-4 sd:h-full sd:px-2 sd:hover:border-neutral"
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      key={gameId}
      to={gameId}
      className="flex shrink-0 items-center gap-2 rounded border border-solid border-transparent transition-all duration-150 ease-in-out max-sd:py-4 sd:h-full sd:px-2 sd:hover:border-neutral"
    >
      {children}
    </Link>
  );
};
