import SAD from "./media/SAD.png";

const ErrorPage = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      <img src={SAD} className="w-56" />

      <h1 className="font-style-heading-h2">Oops, something went wrong</h1>
      <p className="font-style-body-b2 text-neutral">Try refreshing your browser or try again later.</p>
    </div>
  );
};

export { ErrorPage };
