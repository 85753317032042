"use client";

import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "classnames";

const TooltipProvider = TooltipPrimitive.Provider;

const TooltipRoot = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Portal>
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={classNames(
        "bg-primary text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 overflow-hidden rounded-md px-3 py-1.5 text-xs",
        className,
      )}
      {...props}
    />
  </TooltipPrimitive.Portal>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export function Tooltip({
  children,
  content,
  disableOnHover,
  enterDelay,
  isTooltipOpen,
}: {
  children: React.ReactNode;
  content: React.ReactNode;
  disableOnHover?: boolean;
  enterDelay?: number;
  exitDelay?: number;
  isTooltipOpen?: boolean;
}) {
  return (
    <TooltipProvider delayDuration={enterDelay} disableHoverableContent={disableOnHover}>
      <TooltipRoot open={isTooltipOpen}>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent className="bg-background-80">{content}</TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
}
