import { forwardRef, PropsWithChildren, Ref, useEffect, useRef, useState } from "react";
import { Container } from "./styled";

export type ScrollAxis = "auto" | "x" | "y";

interface ScrollableContainerProps {
  axis?: ScrollAxis;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
  style?: React.CSSProperties;
}

const ScrollableContainer = forwardRef(
  (
    { axis = "auto", className, onClick, onScroll, children, style }: PropsWithChildren<ScrollableContainerProps>,
    ref: Ref<HTMLDivElement>,
  ): JSX.Element => {
    const [isScrolling, setIsScrolling] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | undefined>(undefined);

    const handleScroll: React.UIEventHandler<HTMLDivElement> = (event) => {
      if (onScroll) onScroll(event);

      setIsScrolling(true);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);
    };

    useEffect(() => {
      return () => {
        clearTimeout(timerRef.current);
      };
    }, [timerRef]);

    return (
      <Container
        ref={ref}
        className={className}
        axis={axis}
        isScrolling={isScrolling}
        onScroll={handleScroll}
        onClick={onClick}
        style={style}
      >
        {children}
      </Container>
    );
  },
);

ScrollableContainer.displayName = "ScrollableContainer";

export { ScrollableContainer };
