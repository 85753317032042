import {
  Listener,
  SubscribeArgs,
  Unsubscribe,
  PubSubMessage,
  ChatRoom,
  UserAction,
  BroadcastMessage,
  ReconnectListener,
  SessionStateListener,
  AppSyncMessageSchema,
  Namespaces,
} from "./types";
import cookies from "js-cookie";
import { TriggerPoll } from "../../../types/Triggers";
import { environment } from "../../../config";

export * from "./types";

export class PubSubClient {
  private readonly subscriptions = new Map<Namespaces, string>();
  private _sessionOpened = false;
  private readonly host =
    environment === "production"
      ? "ti4rqv5fjbeqnmsfng5ugmfpwi.appsync-api.eu-central-1.amazonaws.com"
      : "du5ibvvopfco3ouaqt26432b6y.appsync-api.eu-central-1.amazonaws.com";

  public get sessionOpened() {
    return this._sessionOpened;
  }

  private set sessionOpened(value: boolean) {
    this._sessionOpened = value;
    this.triggerSessionStateChange();
  }

  private wsInstance: null | WebSocket = null;
  private listeners: Array<Listener> = [];
  private reconnectListeners: Array<ReconnectListener> = [];
  private sessionStateListeners: Array<SessionStateListener> = [];
  private closingTimer: null | number = null;
  private connectionTimeout: null | number = null;
  private connectionTimeoutMs: null | number = null;

  constructor() {
    this.onMessage = this.onMessage.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  public async open() {
    return this.openConnection();
  }

  public close() {
    this.closeConnection();
  }

  public subscribe({
    roomId,
    newMessagesListener,
    deletedMessageListener,
    restoreMessageListener,
    suspensionListener,
    emojiExplosionListener,
    banListener,
    roomConfigChangeListener,
    triggerPollListener,
    broadcastsListener,
    pointsReceivedListener,
  }: SubscribeArgs): Unsubscribe {
    const subscriptionId = Math.random().toString(32).split(".")[1];

    if (
      (newMessagesListener ||
        deletedMessageListener ||
        restoreMessageListener ||
        roomConfigChangeListener ||
        emojiExplosionListener) &&
      roomId &&
      !this.roomExists(roomId)
    ) {
      this.subscribeToRoom(roomId);
    }
    if ((suspensionListener || banListener || pointsReceivedListener) && !this.userActionSubscriptionExists()) {
      this.subscribeToUserActions();
    }

    if (triggerPollListener && !this.triggerSubscriptionExists()) {
      this.subscribeToTriggers();
    }

    if (broadcastsListener && !this.broadcastsSubscriptionExists()) {
      this.subscribeToBroadcasts();
    }

    this.listeners.push({
      id: subscriptionId,
      roomId,
      newMessagesListener,
      deletedMessageListener,
      pointsReceivedListener,
      restoreMessageListener,
      suspensionListener,
      emojiExplosionListener,
      banListener,
      roomConfigChangeListener,
      triggerPollListener,
      broadcastsListener,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const messageListenerIndex: number = this.listeners.findIndex(
            (messageListener) => messageListener.id === subscriptionId,
          );

          if (messageListenerIndex > -1) {
            const removedListener = this.listeners.splice(messageListenerIndex, 1)[0];

            if (
              (removedListener.newMessagesListener ||
                removedListener.emojiExplosionListener ||
                removedListener.deletedMessageListener ||
                removedListener.restoreMessageListener ||
                removedListener.roomConfigChangeListener) &&
              roomId &&
              !this.roomExists(roomId)
            ) {
              this.unsubscribeFromRoom(roomId);
            }
            if (
              (removedListener.suspensionListener ||
                removedListener.pointsReceivedListener ||
                removedListener.banListener) &&
              !this.userActionSubscriptionExists()
            ) {
              this.unsubscribeUserActions();
            }
            if (removedListener.triggerPollListener && !this.triggerSubscriptionExists()) {
              this.unsubscribeFromTriggers();
            }
            if (removedListener.broadcastsListener && !this.broadcastsSubscriptionExists()) {
              this.unsubscribeFromBroadcasts();
            }
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public reconnect(): void {
    this.closeConnection();
    this.openConnection().then(() => {
      this.triggerReconnect();
      const sessionStateChangeSub = this.onSessionStateChange((sessionOpened) => {
        if (!sessionOpened) {
          return;
        }
        sessionStateChangeSub.unsubscribe();

        const rooms: Set<string> = new Set(
          this.listeners.map((messageListener) => messageListener.roomId).filter(Boolean) as Array<string>,
        );

        rooms.forEach((roomId: string) => {
          this.subscribeToRoom(roomId);
        });

        if (this.userActionSubscriptionExists()) {
          this.subscribeToUserActions();
        }
        if (this.triggerSubscriptionExists()) {
          this.subscribeToTriggers();
        }
        if (this.broadcastsSubscriptionExists()) {
          this.subscribeToBroadcasts();
        }
      });
    });
  }

  public onReconnect(callback: () => void): Unsubscribe {
    const reconnectId: string = Math.random().toString(32).split(".")[1];

    this.reconnectListeners.push({
      id: reconnectId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.reconnectListeners.findIndex((listener) => listener.id === reconnectId);

          if (listenerIndex > -1) {
            this.reconnectListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  public onSessionStateChange(callback: (value: boolean) => void): Unsubscribe {
    const sessionStateId: string = Math.random().toString(32).split(".")[1];

    this.sessionStateListeners.push({
      id: sessionStateId,
      callback,
    });

    return {
      unsubscribe: (): boolean => {
        try {
          const listenerIndex: number = this.sessionStateListeners.findIndex(
            (listener) => listener.id === sessionStateId,
          );

          if (listenerIndex > -1) {
            this.sessionStateListeners.splice(listenerIndex, 1);
          }

          return true;
        } catch {
          return false;
        }
      },
    };
  }

  private closeConnection(): void {
    this.stopStateWatcher();

    if (this.wsInstance === null) {
      return;
    }

    this.wsInstance.removeEventListener("message", this.onMessage);
    this.wsInstance.removeEventListener("error", this.onClose);
    this.wsInstance.close();
    this.wsInstance = null;

    if (this.closingTimer !== null) {
      window.clearTimeout(this.closingTimer);
    }
    this.sessionOpened = false;
  }

  private getWsProtocols() {
    const getHeader = () => {
      const auth = {
        host: this.host,
        // authorization is not needed to connect to the websocket,
        // but the value is required to be present
        Authorization: "n/a",
      };

      return btoa(JSON.stringify(auth))
        .replace(/\+/g, "-") // Convert '+' to '-'
        .replace(/\//g, "_") // Convert '/' to '_'
        .replace(/=+$/, ""); // Remove padding `=`
    };

    return [`header-${getHeader()}`, "aws-appsync-event-ws"];
  }

  private openConnection(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.wsInstance = new WebSocket(
        `//${this.host.replace("appsync-api", "appsync-realtime-api")}/event/realtime`,
        this.getWsProtocols(),
      );
      this.wsInstance.addEventListener("message", this.onMessage);
      this.wsInstance.addEventListener("error", this.onClose);
      this.wsInstance.addEventListener("open", () => {
        this.wsInstance?.send(JSON.stringify({ type: "connection_init" }));
        resolve();
      });
    });
  }

  private onClose(): void {
    this.closingTimer = window.setTimeout(() => {
      this.closingTimer = null;
      this.reconnect();
    }, 1000);
  }

  private stopStateWatcher(): void {
    if (this.connectionTimeout !== null) {
      window.clearTimeout(this.connectionTimeout);
      this.connectionTimeout = null;
    }
  }

  private watchConnectionState(): void {
    this.stopStateWatcher();
    if (this.connectionTimeoutMs === null) {
      return;
    }

    this.connectionTimeout = window.setTimeout(() => {
      this.reconnect();
    }, this.connectionTimeoutMs);
  }

  private triggerReconnect() {
    this.reconnectListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback();
      }, 1);
    });
  }

  private triggerSessionStateChange() {
    this.sessionStateListeners.forEach((listener) => {
      setTimeout(() => {
        listener.callback(this.sessionOpened);
      }, 1);
    });
  }

  private onMessage(event: MessageEvent): void {
    const data: { type: string; connectionTimeoutMs: number } = JSON.parse(event.data);

    switch (data.type) {
      case "connection_ack": {
        this.sessionOpened = true;
        // Connection timeout is the period of time after which the client should reconnect
        // if he's not receiving "ka" messages from the server.
        this.connectionTimeoutMs = data.connectionTimeoutMs;
        this.watchConnectionState();
        break;
      }
      case "ka": {
        this.watchConnectionState();
        break;
      }
      case "data": {
        this.onEvent(AppSyncMessageSchema.parse(data).event);
        break;
      }
    }
  }

  private onEvent(data: PubSubMessage): void {
    try {
      switch (data.type) {
        case "chat_room": {
          this.onChatRoom(data.chat_room);
          break;
        }
        case "user": {
          this.onUserAction(data.user);
          break;
        }
        case "trigger": {
          if (data.trigger?.type === "poll") {
            this.onTriggerPoll(data.trigger.data as TriggerPoll);
          }
          break;
        }
        case "broadcast": {
          this.onBroadcast(data.broadcast);
          break;
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  }

  private onChatRoom(chatRoom: ChatRoom): void {
    switch (chatRoom.type) {
      case "emoji_explosion": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.emojiExplosionListener === "function"
          ) {
            setTimeout(() => {
              messageListener.emojiExplosionListener?.(chatRoom.emoji_explosion);
            }, 1);
          }
        });
        break;
      }
      case "message": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.newMessagesListener === "function"
          ) {
            setTimeout(() => {
              messageListener.newMessagesListener?.(chatRoom.message);
            }, 1);
          }
        });
        break;
      }
      case "message_delete": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.deletedMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.deletedMessageListener?.(chatRoom.message_delete);
            }, 1);
          }
        });
        break;
      }
      case "message_restore": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.restoreMessageListener === "function"
          ) {
            setTimeout(() => {
              messageListener.restoreMessageListener?.(chatRoom.message_restore);
            }, 1);
          }
        });
        break;
      }
      case "room_config": {
        this.listeners.forEach((messageListener) => {
          if (
            messageListener.roomId === chatRoom.room_uuid &&
            typeof messageListener.roomConfigChangeListener === "function"
          ) {
            setTimeout(() => {
              messageListener.roomConfigChangeListener?.(chatRoom.room_config);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onUserAction(userAction: UserAction): void {
    switch (userAction.type) {
      case "user_suspension": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.suspensionListener === "function") {
            setTimeout(() => {
              messageListener.suspensionListener?.(userAction.user_suspension);
            }, 1);
          }
        });
        break;
      }

      case "points_received": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.pointsReceivedListener === "function") {
            setTimeout(() => {
              messageListener.pointsReceivedListener?.(userAction.points_received);
            }, 1);
          }
        });
        break;
      }

      case "ban": {
        this.listeners.forEach((messageListener) => {
          if (typeof messageListener.banListener === "function") {
            setTimeout(() => {
              messageListener.banListener?.(userAction.ban);
            }, 1);
          }
        });
        break;
      }
    }
  }

  private onTriggerPoll(trigger: TriggerPoll): void {
    this.listeners.forEach((messageListener) => {
      if (typeof messageListener.triggerPollListener === "function") {
        setTimeout(() => {
          messageListener.triggerPollListener?.(trigger);
        }, 1);
      }
    });
  }

  private onBroadcast(broadcastMessage: BroadcastMessage): void {
    this.listeners.forEach((messageListener) => {
      if (typeof messageListener.broadcastsListener === "function") {
        setTimeout(() => {
          messageListener.broadcastsListener?.(broadcastMessage);
        }, 1);
      }
    });
  }

  private getUserId() {
    const accessToken = cookies.get("access_token");
    if (!accessToken) {
      return undefined;
    }

    const payload = JSON.parse(window.atob(decodeURIComponent(accessToken.split(".")[1])));
    return payload?.username as string;
  }

  private subscribeToRoom(roomId: string) {
    this.subscribeTo(`/chat-room/${roomId}`);
  }

  private unsubscribeFromRoom(roomId: string): void {
    this.unsubscribeFrom(`/chat-room/${roomId}`);
  }

  private subscribeToUserActions(): void {
    const userId = this.getUserId();
    if (!userId) {
      return;
    }

    this.subscribeTo(`/user/${userId}`);
  }

  private unsubscribeUserActions(): void {
    const userId = this.getUserId();
    if (!userId) {
      return;
    }

    this.unsubscribeFrom(`/user/${userId}`);
  }

  private subscribeToTriggers(): void {
    this.subscribeTo("/trigger");
  }

  private unsubscribeFromTriggers(): void {
    this.unsubscribeFrom("/trigger");
  }

  private subscribeToBroadcasts(): void {
    this.subscribeTo("/broadcast");
  }

  private unsubscribeFromBroadcasts(): void {
    this.unsubscribeFrom("/broadcast");
  }

  private subscribeTo(channel: Namespaces): void {
    if (!this.sessionOpened || !this.wsInstance || this.subscriptions.has(channel)) {
      return;
    }

    const subscriptionId = window.crypto.randomUUID();
    this.subscriptions.set(channel, subscriptionId);

    this.wsInstance.send(
      JSON.stringify({
        type: "subscribe",
        id: subscriptionId,
        channel: channel.replace(/[^a-z\d-/]/gi, "-"), // replace all not allowed characters with "-",
        authorization: {
          Authorization: cookies.get("access_token") ?? "n/a",
        },
      }),
    );
  }

  private unsubscribeFrom(channel: Namespaces): void {
    const subscriptionId = this.subscriptions.get(channel);

    if (!this.sessionOpened || !this.wsInstance || !subscriptionId) {
      return;
    }

    this.subscriptions.delete(channel);
    this.wsInstance.send(
      JSON.stringify({
        type: "unsubscribe",
        id: subscriptionId,
      }),
    );
  }

  private roomExists(roomId: string): boolean {
    return this.listeners.some((listener) => listener.roomId === roomId);
  }

  private userActionSubscriptionExists(): boolean {
    return this.listeners.some(
      (listener) =>
        typeof listener.suspensionListener === "function" ||
        typeof listener.banListener === "function" ||
        typeof listener.pointsReceivedListener === "function",
    );
  }

  private triggerSubscriptionExists(): boolean {
    return this.listeners.some((listener) => typeof listener.triggerPollListener === "function");
  }

  private broadcastsSubscriptionExists(): boolean {
    return this.listeners.some((listener) => typeof listener.broadcastsListener === "function");
  }
}
