import { blastDataBucketApi } from "../../../apiClients/blastDataBucketApi";
import { TournamentTimelineSchema } from "../schemas/TournamentTimelineSchema";

interface GetTournamentTimelineOptions {
  gameId: "cs" | "dota" | "rl";
}

export const getTournamentTimeline = async ({ gameId }: GetTournamentTimelineOptions) => {
  const { data } = await blastDataBucketApi.get(`homepage/${gameId}/tournament-timeline.json`);
  return TournamentTimelineSchema.parse(data);
};
