import { z } from "zod";
import { ReactNode } from "react";

export const PointsProductSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  action: z.string(),
  isPublished: z.boolean(),
  isHidden: z.boolean().optional(),
  publishedAt: z.string().datetime().optional(),
  price: z.number().int().min(0),
  cooldown: z.number().int().min(0).optional(),
  isRedeemableFromChat: z.boolean(),
  allowedRedemptions: z.number().int().min(0).optional(),
  description: z.string().optional(),
  redemptionsLeft: z.number().int().min(0).optional(),
  collectionId: z.string().optional(),
  lastPurchasedAt: z.string().datetime().optional(),
  type: z.enum(["default", "raffle-ticket", "raffle-reward"]).default("default"),
});

export const PointsProductResponseSchema = z.object({
  products: PointsProductSchema.array(),
});

export type PointsProduct = z.infer<typeof PointsProductSchema> & {
  title?: string;
  description?: ReactNode;
};

const teamTakeoverSchema = z.object({
  action: z.literal("GRANT_TEAM_TAKEOVER"),
  teamId: z.string(),
  broadcastId: z.string(),
});

const teamTakeoverVoteSchema = z.object({
  action: z.literal("GRANT_TEAM_TAKEOVER_VOTE"),
  pollId: z.string(),
  optionId: z.string(),
});

const headToHeadPollSchema = z.object({
  action: z.literal("GRANT_HEAD_TO_HEAD_POLL"),
  broadcastId: z.string().min(1),
  options: z.string().min(1).max(15).array().length(2),
});

const emojiExplosionSchema = z.object({
  action: z.literal("GRANT_EMOJI_EXPLOSION"),
  broadcastId: z.string().min(1),
  emoji: z.string().emoji().min(1),
});

export const purchaseRequestBodySchema = z
  .union([teamTakeoverSchema, teamTakeoverVoteSchema, headToHeadPollSchema, emojiExplosionSchema])
  .optional()
  .nullable();
export type PurchaseRequestBody = z.infer<typeof purchaseRequestBodySchema>;
