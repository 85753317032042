import { signupURL } from "../../../config";
import { useAuth } from "../../../auth/AuthProvider/hooks/useAuth";
import { useUserProfile } from "../../../auth/useUserProfile";

function useSignupCheck() {
  const { hasAccessToken } = useAuth();

  const userProfile = useUserProfile();

  if (isSignupPage(window.location.pathname)) {
    return;
  }

  // Authenticated users with no profile have not completed the signup process - send them back
  if (hasAccessToken && userProfile === null) {
    return window.location.assign(`${window.location.origin}${signupURL}`);
  }
}

function isSignupPage(pathname: string) {
  const isSignup = pathname.includes(signupURL);
  return isSignup;
}
export { useSignupCheck };
