import { useAlert } from "@providers/AlertProvider/hooks/useAlert";
import { usePointsEvents } from "@shared/hooks/usePointsEvents";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { DateTime } from "luxon";
import { useCallback, useRef, useState } from "react";

export const PointsNotification = () => {
  const [pointsEvent, setPointsEvent] = useState<{ id: number; amount: number; title: string; message: string } | null>(
    null,
  );
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onPointsReceived = useCallback(
    ({ amount, title, message }: { amount: number; title: string; message: string }) => {
      const id = DateTime.now().toMillis();
      setPointsEvent({ id, amount, title, message });

      timerRef.current = setTimeout(() => {
        setPointsEvent(null);
      }, 5000);
    },
    [],
  );

  usePointsEvents({
    onPointsReceived,
  });

  const alert = useAlert();

  return (
    <AnimatePresence>
      {pointsEvent && (
        <motion.button
          className={classNames(
            "z-pointsNotification flex items-center gap-1 rounded-full px-1 py-2 text-primary-100",
            "max-sd:bg-background-100/50 max-sd:shadow-sm max-sd:shadow-background-100", // Add shadow on mobile, in case it falls over with the home button
          )}
          initial={{ opacity: 0, x: 32, scale: 0.5 }}
          animate={{ opacity: 1, x: 0, scale: 1 }}
          exit={{ opacity: 0, x: 32, scale: 0.5 }}
          onClick={() => {
            alert.addPointsNotification(pointsEvent);
          }}
        >
          <span className="font-style-label-l3 whitespace-nowrap">+ {pointsEvent.amount}</span>
        </motion.button>
      )}
    </AnimatePresence>
  );
};
