import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../tournaments/shared/queryKeys";
import { getTournamentTimeline } from ".";

interface UseGetTournamentTimelineOptions {
  gameId: "cs" | "dota" | "rl";
}

export const useGetTournamentTimeline = ({ gameId }: UseGetTournamentTimelineOptions) => {
  return useQuery({
    queryKey: queryKeys.timeline(gameId),
    queryFn: () => getTournamentTimeline({ gameId }),
  });
};
