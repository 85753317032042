import { z } from "zod";
import { TriggerPoll } from "../../../types/Triggers";
import { Message } from "../../../types/Message";
import { Room } from "../../../types/Room";
import { Suspension } from "../../ChatApiClient/types";
import { Broadcast } from "@data/broadcasts/getLiveBroadcasts";
import { BroadcastAds } from "../../../types/BroadcastAds";

type PubSubMessageType =
  | "chat_room"
  | "ping"
  | "connection_metadata"
  | "user"
  | "admin_chat_room"
  | "trigger"
  | "broadcast";

type PubSubChatRoomType = "message" | "message_delete" | "message_restore" | "room_config" | "emoji_explosion";

type PubSubUserActionType = "user_suspension" | "ban" | "points_received";

export interface Ping {
  token: string;
}

export interface ConnectionMetadata {
  connection_id: string;
}

export interface ChatRoom {
  type: PubSubChatRoomType;
  room_uuid: string;
  emoji_explosion: object;
  message: Message;
  message_delete: Message;
  message_restore: Message;
  room_config: Room;
}

export interface UserAction {
  type: PubSubUserActionType;
  user_suspension: Suspension;
  points_received: object;
  ban: object;
  alert: object;
}

export interface BroadcastMessage {
  action: "went-live" | "went-offline" | "ad-executed";
  data: {
    broadcast: Broadcast;
    adRun: BroadcastAds;
  };
}

export interface PubSubMessage {
  type: PubSubMessageType;
  ping: Ping;
  connection_metadata: ConnectionMetadata;
  chat_room: ChatRoom;
  user: UserAction;
  trigger: {
    type: string;
    data: TriggerPoll;
  };
  notification: Notification;
  broadcast: BroadcastMessage;
}

export type NewMessagesListener = (message: Message) => unknown;
export type DeletedMessageListener = (message: Message) => unknown;
export type RestoredMessageListener = (message: Message) => unknown;
export type SuspensionListener = (suspension: null | Suspension) => unknown;
export type BanListener = (ban: object) => unknown;
export type PointsReceivedListener = (notification: object) => unknown;
export type EmojiExplosionListener = (emojiExplosion: object) => unknown;
export type RoomConfigChangeListener = (room: Room) => unknown;
export type TriggerPollListener = (trigger: TriggerPoll) => unknown;
export type BroadcastsListener = (broadcast: BroadcastMessage) => unknown;

export interface Listener {
  id: string;
  roomId?: string;
  newMessagesListener?: NewMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoreMessageListener?: RestoredMessageListener;
  suspensionListener?: SuspensionListener;
  emojiExplosionListener?: EmojiExplosionListener;
  banListener?: BanListener;
  pointsReceivedListener?: PointsReceivedListener;
  roomConfigChangeListener?: RoomConfigChangeListener;
  triggerPollListener?: TriggerPollListener;
  broadcastsListener?: BroadcastsListener;
}

export interface ReconnectListener {
  id: string;
  callback: () => void;
}

export interface SessionStateListener {
  id: string;
  callback: (value: boolean) => void;
}

export interface Unsubscribe {
  unsubscribe: () => boolean;
}

export interface SubscribeArgs {
  roomId?: string;
  newMessagesListener?: NewMessagesListener;
  deletedMessageListener?: DeletedMessageListener;
  restoreMessageListener?: RestoredMessageListener;
  suspensionListener?: SuspensionListener;
  emojiExplosionListener?: EmojiExplosionListener;
  banListener?: BanListener;
  pointsReceivedListener?: PointsReceivedListener;
  roomConfigChangeListener?: RoomConfigChangeListener;
  triggerPollListener?: TriggerPollListener;
  broadcastsListener?: BroadcastsListener;
}

export type Namespaces = `/chat-room/${string}` | `/user/${string}` | "/trigger" | "/broadcast";

export const AppSyncMessageSchema = z.object({
  event: z.string().transform((value) => JSON.parse(value) as PubSubMessage),
});
