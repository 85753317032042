import { useGetTournamentTimeline } from "@data/tournamentTimeline/getTournamentTimeline/useGetTournamentTimeline";
import { GameId } from "@providers/GameIdProvider";
import { NavTopLevelParent } from "../NavTopLevelParent";
import { NavbarChildItem, NavbarTopLevelItem } from "@data/sanity/getSettings/schemas/NavbarSchema";
import { ComponentProps, useMemo } from "react";
import { DateTime } from "luxon";
import { useBreakpoints } from "@shared/helpers/useBreakpoints";
import { MobileNavParent } from "../MobileBurgerMenu/components/MobileNavParent";
import { GroupedNavbarItems } from "../../shared/types";

interface Props {
  topLevelNavItem: NavbarTopLevelItem;
  onNavLinkClick?: () => void;
}

export const TournamentTimelineNav = ({
  gameId,
  ...props
}: {
  gameId: GameId | null;
} & Props) => {
  if (gameId !== "cs" && gameId !== "dota" && gameId !== "rl") {
    // Only for CS, Dota & Rocket League
    return null;
  }

  return <NavItem gameId={gameId} {...props} />;
};

const NavItem = ({ gameId, topLevelNavItem, onNavLinkClick }: { gameId: "cs" | "dota" | "rl" } & Props) => {
  const { data } = useGetTournamentTimeline({ gameId });
  const { isSmallDesktop } = useBreakpoints();
  const baseRoute = topLevelNavItem.route;

  const children: (NavbarChildItem | GroupedNavbarItems)[] = useMemo(() => {
    const children: (NavbarChildItem | GroupedNavbarItems)[] = [
      {
        _key: "all-tournaments",
        title: "All Tournaments",
        _type: "navbarItem",
        route: baseRoute,
      },
    ];

    // Live:
    if (data?.live.length) {
      // Sort by start date descending
      const sorted = data.live.sort((a, b) => b.startDate.localeCompare(a.startDate));

      // Show all if more than one
      children.push({
        _key: "live",
        _type: "groupedNavbarItems",
        badge: "live",
        headerText: "Ongoing",
        children: sorted.map((tournament): GroupedNavbarItems["children"][0] => {
          const startDateTime = DateTime.fromISO(tournament.startDate);
          const startMonth = startDateTime.toFormat("LLL");
          const startDay = startDateTime.toFormat("d");
          const endDateTime = DateTime.fromISO(tournament.endDate);
          const endMonth = endDateTime.toFormat("LLL");
          const endDay = endDateTime.toFormat("d");
          const subText = `${startMonth} ${startDay} - ` + (startMonth === endMonth ? endDay : `${endMonth} ${endDay}`); // "Sep 25 - 29" or "Sep 25 - Oct 1"

          return {
            _key: tournament.id,
            title: tournament.name,
            _type: "navbarItem",
            route: `${baseRoute}/${tournament.id}`,
            subText: subText,
          };
        }),
      });
    }

    // Upcoming:
    if (data?.upcoming.length) {
      // Sort by start date ascending
      const sorted = data.upcoming.sort((a, b) => a.startDate.localeCompare(b.startDate));
      // if live, up to 1, otherwise up to 2 items
      const sliced = sorted.slice(0, data.live.length ? 1 : 2);

      children.push({
        _key: "upcoming",
        _type: "groupedNavbarItems",
        headerText: "Upcoming",
        children: sliced.map(
          (tournament) =>
            ({
              _key: tournament.id,
              title: tournament.name,
              _type: "navbarItem",
              route: `${baseRoute}/${tournament.id}`,
            }) satisfies GroupedNavbarItems["children"][0],
        ),
      });
    }

    // Finished:
    if (data?.past.length) {
      // Sort by start date descending
      const sorted = data.past.sort((a, b) => b.startDate.localeCompare(a.startDate));

      // if live, up to 1, otherwise up to 2 items
      const sliced = sorted.slice(0, data.live.length ? 1 : 2);

      children.push({
        _key: "finished",
        _type: "groupedNavbarItems",
        headerText: "Finished",
        children: sliced.map(
          (tournament) =>
            ({
              _key: tournament.id,
              title: tournament.name,
              _type: "navbarItem",
              route: `${baseRoute}/${tournament.id}`,
            }) satisfies NavbarChildItem,
        ),
      });
    }

    return children;
  }, [data, baseRoute]);

  const navItem: ComponentProps<typeof NavTopLevelParent>["parent"] = useMemo(
    () => ({
      _type: "navbarItem",
      _key: "tournament-timeline-nav",
      route: baseRoute,
      title: "Tournaments",
      children,
    }),
    [children, baseRoute],
  );

  return isSmallDesktop ? (
    <NavTopLevelParent parent={navItem} />
  ) : (
    <MobileNavParent parent={navItem} onNavLinkClick={onNavLinkClick} />
  );
};
