import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import { AuthContext } from "../../../../auth/AuthProvider";
import { getFlags } from ".";

export function useGetFlag<K extends string>(flag: K) {
  const auth = useContext(AuthContext);

  const { data, isLoading: loading } = useQuery({
    queryKey: queryKeys.flags({ userId: auth?.userId, email: auth?.userEmail }),
    queryFn: () => getFlags<K>({ userId: auth?.userId, email: auth?.userEmail }),
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  return { enabled: Boolean(data?.[flag]), loading };
}
