import { AdaptiveLink } from "@shared/components/AdaptiveLink";
import { Icon } from "@shared/components/Icon";
import classNames from "classnames";

export const DotaSearchLink = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <AdaptiveLink
      onClick={onClick}
      className={classNames(
        "flex items-center gap-4 rounded-full border border-primary-100 bg-background-95 px-4 py-2 whitespace-nowrap",
        className,
      )}
      to="/dota/users"
    >
      <span className="block font-style-body-b3 text-foreground-95">Search user by steam ID</span>
      <Icon icon="magnifyingGlass" />
    </AdaptiveLink>
  );
};
