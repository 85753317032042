import { MatchMetadataSchema } from "@data/tournament/shared/types/Series";
import { TeamV2Schema } from "@data/tournament/shared/types/v2/team";
import { z } from "zod";

const TeamSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
});

export const CircuitSchema = z.object({
  name: z.string(),
});

export const MatchTournamentSchema = z.object({
  name: z.string(),
});

const KeyMatchSchema = z.object({
  circuit: CircuitSchema,
  tournament: MatchTournamentSchema,
  id: z.string(),
  name: z.string(),
  type: z.string(),
  scheduledAt: z.string(),
  metadata: MatchMetadataSchema,
  teamA: TeamSchema.nullish(),
  teamB: TeamSchema.nullish(),
  teamAScore: z.number().nullish(),
  teamBScore: z.number().nullish(),
});

const LiveMatchSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.string(),
  scheduledAt: z.string(),
  metadata: MatchMetadataSchema,
  teamA: TeamSchema.nullish(),
  teamB: TeamSchema.nullish(),
  teamAScore: z.number().nullish(),
  teamBScore: z.number().nullish(),
  hasStarted: z.boolean(),
  hasFinished: z.boolean(),
});

export const TournamentSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  location: z.string().nullish(),
  prizePool: z.string().nullish(),
  numberOfTeams: z.number().nullish(),
  keyMatches: KeyMatchSchema.array().optional(),
  todaysMatches: LiveMatchSchema.array().optional(),
  participants: TeamSchema.array().nullish(),
});

export const TeamRankingSchema = z.object({
  teamId: z.string().nullable(),
  teamName: z.string(),
  category: z.string(),
  subCategory: z.string().nullable(),
  points: z.number(),
  calculationDate: z.string(),
  position: z.number(),
});

export const TeamPlayerTimelineSchema = z.object({
  id: z.string(),
  nickname: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  nationality: z.string().nullable(),
  dateOfBirth: z.string().nullable(),
  ingameId: z.string().nullable(),
  gameId: z.string(),
  role: z.string().nullable(),
  fromDate: z.string(),
});

export const TournamentTeamSlotSchema = z.object({
  team: TeamV2Schema.nullable(),
  index: z.number(),
  qualificationMethod: z.string().nullish(),
  bountyValue: z.number().nullish(),
  bountySeed: z.number().nullish(),
});

export type TournamentTeamSlot = z.infer<typeof TournamentTeamSlotSchema>;

export const TournamentPrizeSchema = z.object({
  id: z.string(),
  tournamentId: z.string(),
  teamId: z.string().nullable(),
  prize: z.string(),
  subPrize: z.string().array().nullable(),
  positionStart: z.number(),
  positionEnd: z.number(),
});

export type Tournament = z.infer<typeof TournamentSchema>;
export type LiveMatch = z.infer<typeof LiveMatchSchema>;
export type TeamRanking = z.infer<typeof TeamRankingSchema>;
export type TeamPlayerTimeline = z.infer<typeof TeamPlayerTimelineSchema>;
export type TournamentPrize = z.infer<typeof TournamentPrizeSchema>;
