import { createContext, useEffect, useMemo } from "react";
import { captureException } from "@sentry/browser";
import { ApiService } from "../ApiService";
import { PubSubClient } from "./PubSubClient";

export interface ApiProviderValue {
  apiService: ApiService;
  pubSubClient?: PubSubClient;
}

// eslint-disable-next-line react-refresh/only-export-components
export const ApiContext = createContext<ApiProviderValue | undefined>(undefined);

function createApiService() {
  return new ApiService();
}

// When the PubSub client is instantiated, re-use the instance and update the connection options
let pubSubClient: PubSubClient;
function getPubSubClient() {
  if (pubSubClient === undefined) {
    pubSubClient = new PubSubClient();
  }

  return pubSubClient;
}

/**
 * ApiProvider provides the API service and PubSub client to the application
 * @param children The children to render
 * @param pubsubClientDisabled Flag to disable the PubSubClient connection for testing purposes
 */
function ApiProvider({
  children,
  pubsubClientDisabled = false,
}: {
  children?: React.ReactNode;
  pubsubClientDisabled?: boolean;
}) {
  const pubSubClient = pubsubClientDisabled ? undefined : getPubSubClient();

  const providerValue: ApiProviderValue = useMemo(() => {
    return {
      apiService: createApiService(),
      pubSubClient,
    };
  }, [pubSubClient]);

  // Connect PubSubClient client always
  // To track logged-in user BAN event and more if needed
  useEffect(() => {
    if (!pubSubClient) return;

    let connectionOpened = false;
    const timer = window.setTimeout(() => {
      pubSubClient.open().catch((error) => {
        captureException(`Error while opening PubSubClient connection: ${error}`);
      });
      connectionOpened = true;
    }, 200);

    return () => {
      window.clearTimeout(timer);
      if (connectionOpened) {
        pubSubClient.close();
      }
    };
  }, [pubSubClient]);

  return <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>;
}

export { ApiProvider };
