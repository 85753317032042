import { Loader } from "../Loader";

export const LoadingPage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex size-full items-center justify-center">
        <Loader />
      </div>
    </div>
  );
};
